import React, { Component } from 'react';
import withRouter from '../../services/withRouter';
import { connect } from "react-redux";
import './watch.scss';
import { showLoader, hideLoader } from "../../action/application";
import ApiServices from '../../services/apiServices';
import CryptoJS from 'crypto-js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import axios from 'axios';

class Watch extends Component {
  player;
  playStartTime = null;
  totalWatchTime = 0;

  constructor(props) {
    super(props);
    this.state = {
      videoData: {},
      skipIntro: false,
      loader: false,
      resume: 0,
      userEmail: localStorage.getItem('usermail') || ''  // Retrieve email from localStorage
    };
  }

  async componentWillMount() {
    this.props.dispatch(showLoader());

    // Decrypt user information if it exists in localStorage
    if (localStorage.getItem('userKey')) {
      const bytes = CryptoJS.AES.decrypt(localStorage.getItem('userKey'), process.env.REACT_APP_SECERET_KEY);
      this.userInfo = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }

    this.props.dispatch(hideLoader());
  }

  componentDidMount() {
    const { flimId, episodeId } = this.props.params;
    this.setState({
      videoData: {},
      skipIntro: false,
      loader: true
    }, () => {
      this.getVideoContent(flimId, episodeId);
    });

    // Add listeners for tab close/unload and page visibility change
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  }

  componentWillUnmount() {
    this.stop_video();  // Ensure stop API is called
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  }

  handleBeforeUnload = () => {
    console.log('Tab closed/unloaded');
    this.stop_video(); // Call stop API on tab close/unload
  };

  handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      console.log('Tab hidden');
      this.stop_video(); // Trigger stop when the tab becomes hidden
    }
  };

  getResume() {
    const { flimId, episodeId, type } = this.props.params;
    const url = `movie/tv/resume/${flimId}/${episodeId ? episodeId : ''}`;
    ApiServices.callGetAPI(url).then(data => {
      if (data.status === 200 && data.data.valid) {
        this.setState({
          resume: type === "promo" ? 0 : data.data.data 
        });
      }
    }).catch(error => {
      console.log(error);
    });
  }

  setPlayerConfig(videoInfo) {
    this.getResume();
    const { type } = this.props.params;
    const watchUrl = type === 'promo' ? videoInfo.trailerUrl : videoInfo.videoUrl;

    this.player = new window.Plyr('#player', {
      debug: false,
      autoplay: true,
      title: videoInfo.title,
      iconUrl: 'https://d12it2ctp7su6p.cloudfront.net/shortflix-web-static/image/plyr.svg',
      keyboard: {
        global: true,
      },
      invertTime: false,
      storage: { enabled: false, key: 'plyr' },
      controls: [
        'play-large',
        'restart',
        'rewind',
        'play',
        'fast-forward',
        'progress',
        'duration',
        'current-time',
        'mute',
        'volume',
        'seasons',
        'captions',
        'settings',
        'fullscreen',
      ],
      settings: ['quality'],
      tooltips: {
        controls: true,
      },
      captions: {
        active: true,
        update: true,
      },
      hls: {
        enabled: true,
        src: watchUrl
      }
    });

    // Handle play event
    this.player.on('play', () => {
      this.playStartTime = Date.now();
      console.log('Video playing');
      this.play_video();
    });

    // Handle pause event and dragging the video
    this.player.on('pause', this.handlePauseEvent);

    // Handle video dragging (when time is changed manually)
    this.player.on('timeupdate', () => {
      if (!this.playStartTime) {
        this.playStartTime = Date.now();
      }
    });

    // Handle stop event
    this.player.on('stop', this.stop_video);

    // Set resume position
    this.player.on('loadeddata', () => {
      this.player.currentTime = Number(this.state.resume) || 0;
    });
  }

  handlePauseEvent = () => {
    if (this.playStartTime) {
      const currentTime = Date.now();
      const watchTime = (currentTime - this.playStartTime); // Watch time in milliseconds
      this.totalWatchTime += watchTime;

      // Reset the start time
      this.playStartTime = null;
      console.log('Video paused');
      this.pauser();
    }
  };

  async play_video() {
    const { type } = this.props.params;
    const user = firebase.auth().currentUser;
    if (!user || !user.getIdToken) return;

    const idToken = await user.getIdToken();
    console.log('Sending play event API...');
    console.log(`filmid:${this.state.videoData.filmid}`,`episode:${type === 'series' ? ('short' + this.state.videoData.sequence) : ''}`,
  `position:${this.player?.currentTime || 0}`,`completed:0`,`watchhours:${this.totalWatchTime}`)
    await axios.put(`${process.env.REACT_APP_API_URL}activity/play_video`, 
      {
        filmid: this.state.videoData.filmid,
        episodeid: type === 'series' ? ('short' + this.state.videoData.sequence) : '',
        position: this.player?.currentTime || 0,
        completed: 0,
        watchhours: this.totalWatchTime // Send total watch time in milliseconds
      }, 
      {
        headers: {
          'apikey': process.env.REACT_APP_API_KEY,
          'authorization': idToken
        }
      });
  }

  async pauser() {
    const { type } = this.props.params;
    const user = firebase.auth().currentUser;
    if (!user || !user.getIdToken) return;

    const idToken = await user.getIdToken();
    console.log('Sending pause event API...');
    console.log(`filmid:${this.state.videoData.filmid}`,`episode:${type === 'series' ? ('short' + this.state.videoData.sequence) : ''}`,
    `position:${this.player?.currentTime || 0}`,`completed:0`,`watchhours:${this.totalWatchTime}`)
    await axios.put(`${process.env.REACT_APP_API_URL}activity/pause_video`, 
      {
        filmid: this.state.videoData.filmid,
        episodeid: type === 'series' ? ('short' + this.state.videoData.sequence) : '',
        position: this.player?.currentTime || 0,
        completed: 0,
        watchhours: this.totalWatchTime 
      }, 
      {
        headers: {
          'apikey': process.env.REACT_APP_API_KEY,
          'authorization': idToken
        }
      });
  }

  async stop_video() {
    const { type } = this.props.params;
    const user = firebase.auth().currentUser;
    if (!user || !user.getIdToken) return;

    const idToken = await user.getIdToken();
    console.log('Sending stop event API...');
    console.log(`filmid:${this.state.videoData.filmid}`,`episode:${type === 'series' ? ('short' + this.state.videoData.sequence) : ''}`,
    `position:${this.player?.currentTime || 0}`,`completed:0`,`watchhours:${this.totalWatchTime}`)
    await axios.put(`${process.env.REACT_APP_API_URL}activity/stop_video`, 
      {
        filmid: this.state.videoData.filmid,
        episodeid: type === 'series' ? ('short' + this.state.videoData.sequence) : '',
        position: this.player?.currentTime || 0,
        completed: 0,
        watchhours: this.totalWatchTime // Send total watch time in milliseconds
      }, 
      {
        headers: {
          'apikey': process.env.REACT_APP_API_KEY,
          'authorization': idToken
        }
      });
  }

  goBack() {
    const { type } = this.props.params;
    if (this.player.currentTime && this.player.currentTime > 0 && type !== 'promo') {
      this.updateWatchedDuration();
    }
    if (this.player) {
      this.player.destroy(() => { }, true);
    }
    window.history.back();
  }

  async updateWatchedDuration() {
    const { type } = this.props.params;
    const position = (this.player && this.player.currentTime) ? Math.round(this.player.currentTime) : 0;
    const completed = (this.player && this.player.ended === true) ? 1 : 0;

    this.player.pause();
    this.player.stop();
    this.player.destroy();
    await ApiServices.callPutAPI('activity/stop_video', {
      position: position,
      episodeid: type === 'series' ? ('short' + this.state.videoData.sequence) : '',
      filmid: this.state.videoData.filmid,
      completed: completed
    });
  }

  getVideoContent(flimId, episodeId = null) {
    const url = episodeId ? `movie/tv/${flimId}/episodes` : `movie/tv/${flimId}`;
    ApiServices.callGetAPI(url).then(result => {
      if (result.status === 200 && result.data.valid) {
        if (result.data.data) {
          let epIndex = 0;
          for (let i = 0; i < result.data.data.length; i++) {
            if (result.data.data[i].episodeid === episodeId) {
              epIndex = i;
            }
          }
          const videoInfo = episodeId ? result.data.data[epIndex] : result.data.data;
          this.setState({
            videoData: videoInfo,
            skipIntro: false,
            loader: false
          }, () => {
            this.setPlayerConfig(videoInfo);
          });
        }
      }
    }).catch(error => {
      console.log(error);
    });
  }

  skipIntro() {
    this.player.currentTime = Number(this.state.videoData.skipend);
  }

  render() {
    return (
      <>
        {!this.state.loader ?
          <div className="video-plyr flex" style={{ marginTop: "60px" }}>
            <video controls playsInline id="player" autoPlay>
            </video>
            <div id="inner-video">
              <span className='close-icon-video' onClick={() => this.goBack()}>
                <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m12.45 37.65-2.1-2.1L21.9 24 10.35 12.45l2.1-2.1L24 21.9l11.55-11.55 2.1 2.1L26.1 24l11.55 11.55-2.1 2.1L24 26.1Z" /></svg>
              </span>
              {this.state.skipIntro && <span className='skip-intro' onClick={() => this.skipIntro()}>Skip Intro</span>}
              <div className="video-titles">
                <div className="player-title-detail">
                  <h2>{this.state.videoData.title}</h2>
                </div>
              </div>
            </div>
          </div>
          : ''}
      </>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.application.loading,
  videoInfo: state.application.videoInfo,
  PreviousUrl: state.application.PreviousUrl
});

export default withRouter(connect(mapStateToProps)(Watch));
